import { TokenInformation } from '@crescentengage/cesvcapilib/dist/@types/TokenInformation';

const Token = {
    read: (): TokenInformation<Date> | null => {
        const TokenRecord = localStorage.getItem('apikeyrecord');
        if (!TokenRecord) return null;
        const ParsedData = JSON.parse(atob(TokenRecord)) as TokenInformation<string>;
        return {
            token_type: ParsedData.token_type,
            access_token: ParsedData.access_token,
            expires_at: new Date(ParsedData.expires_at),
        };
    },
    write: (TokenData: TokenInformation<Date> | TokenInformation<string>) => {
        localStorage.setItem('apikeyrecord', btoa(JSON.stringify(TokenData)));
    },
    remove: () => {
        localStorage.removeItem('apikeyrecord');
    },
};

export default Token;

<template>
    <div class="container">
        <p>{{ msg }}</p>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Auth } from '@crescentengage/cesvcapilib';
import Token from '@/mixins/Token';

export default class CallbackView extends Vue {
    msg = 'Meigetsu IDでのサインイン・サインアップを実行中です';
    mounted() {
        const Params = new URLSearchParams(location.search);
        const AuthCode = Params.get('code');
        if (AuthCode == null) {
            this.msg = '認証できませんでした。';
            return;
        }
        Auth.GetToken(AuthCode)
            .then(token => {
                Token.write(token);
                location.href = '/matching/';
            })
            .catch((er: Error) => {
                this.msg = er.message;
            });
    }
}
</script>

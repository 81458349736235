<template>
    <div class="container">
        <p>サインアウトが完了しました。</p>
        <p>ご利用ありがとうございました。</p>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Auth } from '@crescentengage/cesvcapilib';
import Token from '@/mixins/Token';

export default class SignOutView extends Vue {
    mounted() {
        const TokenRecord = Token.read();
        if (TokenRecord == null) return;
        Auth.SignOut(TokenRecord).catch((er: Error) => {
            console.error(er);
        });
    }
}
</script>

<template>
    <div>
        <section v-if="profile == null">
            <p>{{ msg }}</p>
        </section>
        <section v-else>
            <div class="profile_image">
                <img v-if="profile.account.image == null" src="../assets/noimage.png" alt="result.name" />
                <img v-else src="'/api/v1/image/' + profile.account.image" alt="profile image" />
            </div>
            <dl v-if="mypage" class="profile">
                <dt>ユーザーID:</dt>
                <dd>{{ profile.account.id }}</dd>
                <dt>名前:</dt>
                <dd>{{ profile.account.name }}</dd>
                <dt>性別:</dt>
                <dd>{{ profile.account.gender }}</dd>
                <dt>誕生日:</dt>
                <dd>
                    {{
                        profile.basic_profile == null || profile.basic_profile.birthday == null
                            ? '未登録'
                            : profile.basic_profile.birthday
                                  .toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
                                  .replaceAll('/', '-')
                    }}
                </dd>
                <dt>都道府県:</dt>
                <dd>
                    {{
                        profile.basic_profile == null || profile.basic_profile.prefecture == null
                            ? '未登録'
                            : profile.basic_profile.prefecture
                    }}
                </dd>
                <dt>メールアドレス:</dt>
                <dt>アカウント作成日:</dt>
                <dd>
                    {{
                        profile.account.create_date
                            .toLocaleDateString('ja-JP', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            })
                            .replaceAll('/', '-')
                    }}
                </dd>
                <dt>自己紹介:</dt>
                <dd>
                    {{
                        profile.profile == null || profile.profile.self_introduction == null
                            ? ''
                            : profile.profile.self_introduction
                    }}
                </dd>
                <dt>趣味:</dt>
                <dd>
                    {{
                        profile.profile == null || profile.profile.hobby == null ? '' : profile.profile.hobby.join('、')
                    }}
                </dd>
                <dt></dt>
            </dl>
            <dl v-else class="profile">
                <dt>ユーザーID:</dt>
                <dd>{{ profile.account.id }}</dd>
                <dt>名前:</dt>
                <dd>{{ profile.account.name }}</dd>
                <dt>性別:</dt>
                <dd>{{ profile.account.gender }}</dd>
                <dt>誕生日:</dt>
                <dd>
                    {{
                        profile.basic_profile == null || profile.basic_profile.birthday == null
                            ? '未登録'
                            : profile.basic_profile.birthday
                                  .toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
                                  .replaceAll('/', '-')
                    }}
                </dd>
                <dt>都道府県:</dt>
                <dd>
                    {{
                        profile.basic_profile == null || profile.basic_profile.prefecture == null
                            ? '未登録'
                            : profile.basic_profile.prefecture
                    }}
                </dd>
                <dt>アカウント作成日:</dt>
                <dd>
                    {{
                        profile.account.create_date
                            .toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
                            .replaceAll('/', '-')
                    }}
                </dd>
                <dt>自己紹介:</dt>
                <dd>
                    {{
                        profile.profile == null || profile.profile.self_introduction == null
                            ? ''
                            : profile.profile.self_introduction
                    }}
                </dd>
                <dt>趣味:</dt>
                <dd>
                    {{
                        profile.profile == null || profile.profile.hobby == null ? '' : profile.profile.hobby.join('、')
                    }}
                </dd>
            </dl>
        </section>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Resource } from '@crescentengage/cesvcapilib';
import { AllPublicUserData, AllUserData } from '@crescentengage/cesvcapilib/dist/@types/AllUserData';
import Token from '@/mixins/Token';

export default class UserPageView extends Vue {
    mypage = location.pathname === '/matching/user';
    profile: AllPublicUserData<Date> | AllUserData<Date> | null = null;
    msg = '読み込み中...';
    mounted() {
        const TokenInfo = Token.read();
        if (TokenInfo == null) {
            this.msg = '再度サインインしてください';
            location.href = '/';
            return;
        }
        const GetAccount = (): Promise<void> => {
            if (this.mypage) {
                return Resource.GetUserRecord(TokenInfo).then((data: AllUserData<Date>) => {
                    this.profile = data;
                });
            } else {
                const GetID = () =>
                    typeof this.$route.params.id === 'string' ? this.$route.params.id : this.$route.params.id[0];
                return Resource.GetUserRecord(TokenInfo, GetID()).then((data: AllPublicUserData<Date>) => {
                    this.profile = data;
                });
            }
        };
        GetAccount().catch((er: Error) => {
            this.msg = er.message;
        });
    }
}
</script>

<style scoped>
.profile_image {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

dl.profile {
    display: flex;
    flex-wrap: wrap;
}

dl.profile dt {
    width: 50%;
    margin-bottom: 10px;
}

dl.profile dd {
    width: 80%;
    margin-bottom: 10px;
}
</style>

<template>
    <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
    <section>
        <div>
            <form class="search_container">
                <input id="search_box" type="text" placeholder="キーワード検索" />
                <input type="submit" value="検索" @click="search" />
            </form>
            <div id="search_result">
                <section v-if="searchResults.length === 0 && search_done">
                    <p>検索結果がありません</p>
                </section>
                <section v-else>
                    <h4 v-if="searchResults.length > 0 && search_done">検索結果</h4>
                    <ul class="scroll_content">
                        <li v-for="result in searchResults" :key="result.id">
                            <a class="scroll_content_item" :href="/profile/ + result.id">
                                <img v-if="result.image == null" src="../assets/noimage.png" alt="result.name" />
                                <img v-else src="'/api/v1/image/' + result.image" alt="result.name" />
                                <p>{{ result.name }}</p>
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
        <div>
            <h4>あなたへのおすすめ</h4>
            <div id="recommendation">
                <section v-if="items.length === 0">
                    <p>{{ msg }}</p>
                </section>
                <section v-else>
                    <ul class="scroll_content">
                        <li v-for="item in items" :key="item.id">
                            <a class="scroll_content_item" :href="/profile/ + item.id">
                                <img v-if="item.image == null" src="../assets/noimage.png" alt="item.name" />
                                <img v-else :src="'/api/v1/image/' + item.image" alt="item.name" />
                                <p>{{ item.name }}</p>
                            </a>
                        </li>
                    </ul>
                </section>
                <p>
                    ※ここに表示されるおすすめは、あなたがこれまで検索したキーワードや、登録した趣味等に基づいて表示されています。
                </p>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import Token from '@/mixins/Token';
import { Resource } from '@crescentengage/cesvcapilib';
import { RecommendationItem } from '@crescentengage/cesvcapilib/dist/@types/RecommendationItem';
import { SearchEngineGetResult } from '@crescentengage/cesvcapilib/dist/@types/SearchEngineGetResult';
import { Vue } from 'vue-class-component';

export default class IndexView extends Vue {
    items: RecommendationItem[] = [];
    searchResults: SearchEngineGetResult[] = [];
    search_done = false;
    msg = '取得中...';
    mounted() {
        const TokenData = Token.read();
        if (TokenData == null) {
            this.msg = '再度サインインして下さい';
            location.href = '/';
            return;
        }
        fetch('/api/v1/recommendation', {
            method: 'GET',
            headers: {
                Authorization: `${TokenData.token_type} ${TokenData.access_token}`,
            },
        })
            .then(res => {
                if (res.status !== 200) return Promise.reject(new Error('おすすめ情報の取得に失敗しました'));
                return res.json();
            })
            .then((data: { items: RecommendationItem[] }) => {
                this.items = data.items;
            })
            .catch((er: Error) => {
                this.msg = er.message;
            });
    }
    search() {
        const searchInputBox = document.getElementById('search_box') as HTMLInputElement;
        const keywords = searchInputBox.value.match(/(?:[^\s"]+|"[^"]*")+/g) || [];
        if (keywords.length === 0) return;
        const TokenData = Token.read();
        if (TokenData == null) {
            alert('再度サインインして下さい');
            return;
        }
        Resource.SearchUser(TokenData, keywords)
            .then(data => {
                this.searchResults = data;
            })
            .catch((er: Error) => {
                alert(er.message);
            });
    }
}
</script>

<style scoped>
div {
    margin-bottom: 2em;
}

.scroll_content {
    text-align: center;
}

.search_container {
    box-sizing: border-box;
    position: relative;
    border: 1px solid #999;
    display: block;
    padding: 3px 10px;
    border-radius: 20px;
    height: 2.3em;
    width: 265px;
    overflow: hidden;
}
.search_container input[type='text'] {
    border: none;
    width: 200px;
    height: 2em;
    background-color: #efeee9;
}
.search_container input[type='text']:focus {
    outline: 0;
}
.search_container input[type='submit'] {
    cursor: pointer;
    font-family: FontAwesome;
    border: none;
    background: #3879d9;
    color: #fff;
    position: absolute;
    width: 3.5em;
    height: 3em;
    right: 0px;
    top: -3px;
    outline: none;
}

.scroll_content {
    /* リスト全体のスタイル */
    display: flex;
    max-width: 960px;
    margin: auto;
    overflow-x: auto;
}

.scroll_content li {
    /* 各リストのスタイル */
    width: 100%;
    max-width: 220px;
    padding: 8px;
    margin: 3px;
    flex-shrink: 0;
    list-style: none;
}

.scroll_content img {
    /* 画像のスタイル */
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
}

.scroll_content::-webkit-scrollbar {
    height: 12px; /* スクロールバーの高さ */
}

.scroll_content::-webkit-scrollbar-thumb {
    background: #aaa; /* ツマミの色 */
    border-radius: 6px; /* ツマミ両端の丸み */
}

.scroll_content::-webkit-scrollbar-track {
    background: #ddd; /* トラックの色 */
    border-radius: 6px; /* トラック両端の丸み */
}

@media screen and (min-width: 450px) {
    .scroll_content li {
        width: 45%;
    }
}
</style>

<template>
    <div class="page-header">
        <img alt="logo" src="../assets/logo.png" />
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class PageHeader extends Vue {}
</script>

<template>
    <div class="container">
        <header>
            <PageHeader></PageHeader>
        </header>
        <main>
            <router-view></router-view>
        </main>
        <footer class="border-top footer text-muted">
            <PageFooter></PageFooter>
        </footer>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import PageHeader from '@/components/PageHeader.vue'; // @ is an alias to /src
import PageFooter from '@/components/PageFooter.vue'; // @ is an alias to /src
import { RouteLocationNormalizedLoaded } from 'vue-router';

@Options({
    components: {
        PageHeader,
        PageFooter,
    },
})
export default class HomeView extends Vue {
    path = location.pathname;
    createTitleDesc(routeInstance: RouteLocationNormalizedLoaded) {
        //titleを設定する処理
        if (routeInstance.meta && routeInstance.meta.title) {
            const setTitle = routeInstance.meta.title + ' | 恋する三日月';
            document.title = setTitle;
        } else {
            document.title = '恋する三日月';
        }
    }
    mounted() {
        const routeInstance = this.$route;
        this.createTitleDesc(routeInstance);
        console.log(location.pathname);
    }
}
</script>
<style scoped>
main {
    display: flex;
    flex-direction: row;
}
</style>

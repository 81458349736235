<template>
    <div class="container">
        <section class="caution">
            <section class="red_text">
                <h4>注意</h4>
                <p>
                    このサービスは、出会い系サイト規制法第２条第２項の規定に該当するサービスであり、１８歳未満の方は利用することができません。
                </p>
                <p>また、１８歳以上の方であっても、高校生の方のご利用はできません。</p>
                <p>
                    ご利用にあたりましては、同法第１１条の規定により、氏名、住所、生年月日（以下、基本個人情報）が登録されている
                    <a href="https://idportal.meigetsu.jp/">Meigetsu ID</a>による年齢確認が必要になります。
                </p>
                <p>
                    ただしMeigetsu IDに基本個人情報が登録してある場合であっても、Meigetsu
                    IDのマイページ上の「本人確認・年齢確認レベル」が2または3ではないアカウントでのご利用はできません。
                </p>
            </section>
            <section class="signin">
                <a href="/signin">
                    <img src="../assets/signin.png" />
                </a>
                <a href="https://idportal.meigetsu.jp/signup">
                    <img src="../assets/signup.png" />
                </a>
            </section>
            <section>
                <h4>参考法令</h4>
                <p>
                    <a href="https://elaws.e-gov.go.jp/document?lawid=415AC0000000083">
                        インターネット異性紹介事業を利用して児童を誘引する行為の規制等に関する法律（出会い系サイト規制法）
                    </a>
                </p>
            </section>
        </section>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class TopPageView extends Vue {}
</script>

<style scoped>
.caution {
    /*background-color: rgba(255, 255, 255, 0.88);*/
    padding: 1rem;
    text-align: center;
}

.signin {
    padding: 1rem;
}

.signin a {
    display: flex;
}

.signin img {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
}

.caution .red_text p {
    color: red;
}
</style>

<template>
    <div class="container">
        <p>Copyright &copy; 2024 Meigetsu / Crescent Engage. All rights reserved</p>
        <p>東京都港区南青山２－２ ５Ｆ</p>
        <p>東京都公安委員会 30230071-000</p>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class PageFooter extends Vue {}
</script>

<template>
    <div class="container">
        <p>{{ msg }}</p>
    </div>
</template>

<script lang="ts">
import { Auth } from '@crescentengage/cesvcapilib';
import { Vue } from 'vue-class-component';

export default class AuthorizationView extends Vue {
    msg = 'Meigetsu IDへリダイレクトします。これには時間がかかることがあります。';
    mounted() {
        Auth.InitMeigetsuIDAuthorization().catch((er: Error) => {
            this.msg = er.message;
        });
    }
}
</script>

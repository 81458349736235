import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import IndexView from '@/views/IndexView.vue';
import UserPageView from '@/views/UserPageView.vue';
import TopPageView from '@/views/TopPageView.vue';
import AuthorizationView from '@/views/AuthorizationView.vue';
import CallbackView from '@/views/CallbackView.vue';
import SignOutView from '@/views/SignOutView.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'top',
        component: TopPageView,
        meta: { title: 'トップ' },
    },
    {
        path: '/signin',
        name: 'auth',
        component: AuthorizationView,
        meta: { title: 'サインイン・サインアップ' },
    },
    {
        path: '/meigetsuid/callback',
        name: 'callback',
        component: CallbackView,
        meta: { title: 'サインイン・サインアップ' },
    },
    {
        path: '/signout',
        name: 'signout',
        component: SignOutView,
        meta: { title: 'サインアウト' },
    },
    {
        path: '/matching/',
        name: 'home',
        component: IndexView,
        meta: { title: 'ホーム' },
    },
    {
        path: '/matching/user/:id',
        name: 'user_profile',
        component: UserPageView,
    },
    {
        path: '/matching/user',
        name: 'mypage',
        component: UserPageView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
